.container-policy {
  margin-top: 1.5rem;
  margin-bottom: 5rem;
  align-items: start;
}

.policy-ordered-list {
  padding-left: 0;
  min-width: 210px;
  margin-top: 2rem;
}

.policy-link {
  font-size: 14px;
  color: #7a7d81;
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
  line-height: 1.5;
}

.sidebar-heading {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.policy-link:hover {
  color: red;
  cursor: pointer;
}

.policy-left {
  top: 0;
  position: sticky;
  height: 100vh;
  overflow-y: scroll;
}

.sidebar-arrow {
  transition: all 1s;
}

.main-content {
  padding: 7px 0 0 30px;
}

#policy-heading {
  margin-bottom: 1.5rem;
}

.policy-section {
  margin-bottom: 2.5rem;
}

.policy-ordered-list li {
  margin-bottom: 25px;
}
.policy-subHeadings {
  margin-top: 23px;
}

.policy-subHeadings h6 {
  font-size: 1rem;
}

.policy-link-container {
  border-left: 1px solid #7a7d81;
  margin-left: 1rem;
  margin-top: 0.7rem;
  padding: 0px 8px;
}

@media screen and (width<992px) {
  .main-content {
    padding: 7px 0 0 0px;
  }
  .policy-left {
    position: static;
    height: fit-content;
    overflow-y: hidden;
  }
}

@media screen and (768px <= width <= 992px) {
  .container-policy {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
  }
  .policy-left {
    padding-left: 0;
    margin-left: 1rem;
    margin-top: 0;
  }
  .policy-right {
    min-width: 100%;
    padding: 0;
  }

  .policy-ordered-list {
    position: static;
  }

  .policy-ordered-list li {
    padding-left: 0;
    margin-bottom: 1rem;
  }
}

@media screen and (width < 768px) {
  .container-policy {
    max-width: 100%;
    padding: 0 1.2rem;
  }
  .policy-left {
    margin-top: 0;
  }

  .policy-ordered-list {
    position: static;
  }

  .policy-ordered-list li {
    padding-left: 0;
    margin-bottom: 1rem;
  }
}

.policy-ordered-list li:last-child {
  margin-bottom: 3rem;
}
