/* Loader.css */

.loader-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999; /* Ensure it's above other content */
}

.loader-spinner {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dark-bg {
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
}

.light-bg {
    background-color: rgba(0, 0, 0, 0.4); 
}