.App {
  text-align: center;
  margin-top: 160px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.grecaptcha-badge { 
  visibility: hidden !important;
}

.landing-cover {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 60vh;
  background-color: #ff3f39;
  white-space: normal;
  text-align: left;
}

@media screen and (max-width: 479px) {
  .landing-cover {
    background-position: top 50%;
    padding: 10px;
    background-size: auto, cover;
    background-repeat: no-repeat;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: black !important;
  border-radius: 10px;
  top: 0;
}
.rang
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Mui-error {
  margin: 0 !important;
}

.mapboxgl-popup-close-button {
  margin-right: 8px;
  margin-top: 5px;
  color: black;
}
.MuiGrid-root.MuiGrid-container.parcel-selector-grid > div{
  padding:auto 0px !important;
}

.parcel-input-tabs.MuiGrid-root.MuiGrid-container.parcel-selector-grid > div:nth-child(2) >div>div:nth-child(1){
  width: 100% !important;
  margin: 0  !important;
  text-align: center !important;
}

.parcel-input-tabs{
  
  margin: 0 auto !important;
  margin-top: 8px !important;
  padding: 0 !important;
  align-items: center !important;
}

.parcel-input-tabs div{
  justify-content: center !important;
}

@media (max-width:1049px){
  .parcel-input-tabs div div:nth-child(1) div div > div > div{
    display: flex !important;
    flex-direction: column !important;
  }
  .parcel-input-tabs div div:nth-child(1) div div{
    display: grid !important;
    align-items: center !important;
    justify-content: center !important;
    grid-template-columns: repeat(3,auto);
    width:fit-content !important;
    gap: 4px !important;
  }
  .parcel-input-tabs div div:nth-child(1) div div>div .unit-input-container{
    display: flex !important;
    flex-direction: row !important;    

  }
  .parcel-selector-grid{
    row-gap: 25px !important;
  }
  
}

@media (max-width:599px) {
  .parcel-input-tabs > div > div:nth-child(1){
    padding-top: 0 !important;
  }
  .parcel-selector-grid>div:nth-child(2) {
    margin-bottom: 25px !important;
    margin-top: 0px !important;
  }
}

@media (max-width:500px) {
  .parcel-input-tabs div div:nth-child(1) div div {
    gap: 1px !important;
  }
}

@media (max-width:1049px) {
  .parcel-selector-our-boxes {
      display: grid !important;
      grid-template-columns: 1fr !important;
    }
}

.diagonal-strikethrough {
  position: relative;
}

.diagonal-strikethrough::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  border-top: 1px solid black;
  transform: rotate(8deg);
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}