.step_input {
    display: flex;
    gap: 8px;
    align-items: center;
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 9999px;
    border: 1px solid #D0D5DD;
    opacity: 0.8px;
    color: #667085;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.label {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    color: #344054;
}

@media screen and (max-width: 1250px) {
    .label {
        font-size: 16px;
    }
}
@media screen and (max-width: 1000px) {
    .label {
        font-size: 14px;
    }
}

@media screen and (max-width: 892px) {
    .label {
        font-size: 18px;
    }
}