.path {
    fill: transparent;
    stroke: #ff403a;
    stroke-width: 0.5;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: drawPath 2s linear forwards infinite;
  }

  @keyframes drawPath {
    to {
      stroke-dashoffset: 0;
      fill: #ff403a;
    }
  }